<template>
  <v-row no-gutters class="projects-box" :class="boxClass">
    <ProjectCard
      v-for="(config, index) in configs"
      :key="index"
      :index="index"
      v-bind="config"
      :active="activeIndex == index"
      @activeEvent="handleActive"
    />
  </v-row>
</template>

<script>
import ProjectCard from "./projectCard.vue";
export default {
  props: {
    configs: Array,
  },
  components: {
    ProjectCard,
  },
  data: () => ({
    activeIndex: -1,
  }),
  computed: {
    boxClass() {
      if (this.activeIndex == 0) return "active-type1";
      if (this.activeIndex == 1) return "active-type2";
      if (this.activeIndex == 2) return "active-type3";
      return "";
    },
  },
  methods: {
    handleActive(index) {
      this.activeIndex = index;
    },
  },
};
</script>