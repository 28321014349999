<template>
  <section class="page-projects mt-200">
    <v-container>
      <p class="section-title section-title__product white--text text-center">
        專案經驗
      </p>
    </v-container>

    <v-container class="mt-md-20 project-padding">
      <ProjectRow :configs="cardConfigs1" />
    </v-container>

    <v-container class="mt-md-20 project-padding">
      <ProjectRow :configs="cardConfigs2" />
    </v-container>
    <!-- Talk -->
    <Talk class="mb-4 mt-3" />
    <!-- footer -->
    <Footer />
  </section>
</template>
<script>
import Talk from "@/components/talk.vue";
import Footer from "@/components/footer.vue";
import ProjectRow from "@/components/project/projectRow.vue";

export default {
  components: {
    Talk,
    Footer,
    ProjectRow,
  },
  data: () => ({
    cardConfigs1: [
      {
        title: "伊雲谷",
        subTitle: "企業官網 / 審核機制後台",
        imgUrl: require("@/assets/img/projects/p1.png"),
        showInfo: false,
        isOuterUrl: false,
        website: "detail.ecv",
        infoTitle: "Scheme Design",
        infoSubTitle: `We ensure an intelligent, technical and commercial solution that
              suits your individual circumstances. Our teams have the knowledge
              and experience to design specifications that use innovative and
              state-of-the-art solutions. We manage the potential challenges of
              co-ordinating a largescale project by keeping as many of the works
              in house as possible using our own team. We have close
              relationships with our equipment partners so that we are
              well-informed of new product sets and installation methods,
              ensuring that we are best placed to provide the options available
              to you.`,
      },
      {
        title: "客家電視台",
        subTitle: "串流影音平台 / APP",
        imgUrl: require("@/assets/img/projects/p2.png"),
        showInfo: false,
        isOuterUrl: false,
        website: "detail.hakka",
        infoTitle: "Scheme Design",
        infoSubTitle: `We ensure an intelligent, technical and commercial solution that
              suits your individual circumstances. Our teams have the knowledge
              and experience to design specifications that use innovative and
              state-of-the-art solutions. We manage the potential challenges of
              co-ordinating a largescale project by keeping as many of the works
              in house as possible using our own team. We have close
              relationships with our equipment partners so that we are
              well-informed of new product sets and installation methods,
              ensuring that we are best placed to provide the options available
              to you.`,
      },
      {
        title: "增上資糧",
        subTitle: "IoT / APP",
        imgUrl: require("@/assets/img/projects/p3.png"),
        showInfo: true,
        isOuterUrl: true,
        website: "https://www.unibless.com/",
        infoTitle: "增上資糧IoT APP",
        infoSubTitle: `
        <ul>
          <li>透過藍牙連線串聯智慧佛珠，自動記錄念佛次數</li>
          <li>線上即時皈依供養</li>
          <li>全方位的學習紀錄功能，支援拍照、錄音、文字、社群分享</li>
        </ul>
      `,
      },
    ],
    cardConfigs2: [
      {
        title: "屋比",
        subTitle: "房屋比價網",
        imgUrl: require("@/assets/img/projects/p4.png"),
        showInfo: true,
        isOuterUrl: true,
        website: "https://www.ubee.io/",
        infoTitle: "Ubee 買屋比價",
        infoSubTitle: `
        <ul>
          <li>爬取各大房仲平台物件資訊</li>
          <li>累計百萬筆物件資料，且每日更新十數萬筆</li>
          <li>歸戶整理，物件更新比對，降價通知</li>
          <li>房仲會員線上即時搶單</li>
        </ul>
      `,
      },
      {
        title: "Design+",
        subTitle: "電商平台",
        imgUrl: require("@/assets/img/projects/p5.png"),
        showInfo: true,
        isOuterUrl: true,
        website: "https://www.designplus.style/",
        infoTitle: "design plus電商平台",
        infoSubTitle: `
        <ul>
          <li>多樣性的模組化區塊拼湊出多種組合</li>
          <li>高度金物流整合，提供詳細的物流進度追蹤</li>
          <li>庫存管理</li>
          <li>豐富的行銷模組</li>
        </ul>
      `,
      },
      {
        title: "FiToBe",
        subTitle: "教學平台",
        imgUrl: require("@/assets/img/projects/p6.png"),
        showInfo: true,
        infoTitle: "FiToBe 健康生活平台",
        infoSubTitle: `
        <ul>
          <li>可設定帶狀課程，最多可以安排一年</li>
          <li>功能強大的課程設定方式</li>
          <li>依照課程門票的設定，教練可以預估收入</li>
        </ul>
`,
      },
    ],
  }),
};
</script>
