<template>
  <v-col cols="12" md="4" class="projects-card mb-5" :class="className">
    <v-img
      :src="imgUrl"
      width="100%"
      aspect-ratio="1"
      class="projects-card-img"
    ></v-img>
    <div class="mt-5 pa-8 d-flex justify-space-between">
      <div>
        <h2 class="projects-card__title">{{ title }}</h2>
        <p class="projects-card__subtitle">{{ subTitle }}</p>
      </div>
      <div @click="action" class="projects-card-btn">
        <v-img
          class="d-block"
          width="25"
          height="25"
          src="@/assets/img/icon/arrow.svg"
        ></v-img>
      </div>
    </div>
    <div v-if="showInfo" class="projects-inner">
      <h1 class="mb-10 projects-inner__title">{{ infoTitle }}</h1>
      <p v-html="infoSubTitle" class="projects-inner__content"></p>
      <div class="d-flex">
        <v-btn @click="inactive" large rounded class="mt-3 mb-3 mr-1"> 返回 </v-btn>
        <v-btn v-if="website" @click="goWebsite" large rounded class="ma-3"> 前往網站 </v-btn>
      </div>
      <div></div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
    },
    imgUrl: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    infoTitle: {
      type: String,
    },
    infoSubTitle: {
      type: String,
    },
    isOuterUrl: {
      type: Boolean,
      default: false,
    },
    website: {
      type: String,
    },
    active: Boolean
  },
  data: () => ({

  }),
  computed: {
    projectCardLocation() {
      if (this.index == 0) return "projects-card__left";
      if (this.index == 1) return "projects-card__middle";
      if (this.index == 2) return "projects-card__right";
      return "";
    },
    className() {
      return {
        [this.projectCardLocation]: true,
        active: this.active,
      };
    },
  },
  methods: {
    action() {
      if (this.showInfo) {
        this.$emit('activeEvent', this.index)
        return;
      }
      this.goWebsite();
    },
    goWebsite() {
      if (this.isOuterUrl) {
        window.location.href = this.website;
        return;
      }
      this.$router.push({ name: this.website });
    },
    inactive() {
      this.$emit('activeEvent', -1)
    },
  },
};
</script>